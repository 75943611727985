import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { stringIsNullOrEmpty, createMultiPartFormBody, isObjectEmpty } from '../../../common/util';
import { ApiKey, OrderStatus } from '../../../common/constant';
import { WebUrl } from '../../../routes';
import { useRouter } from '../../../common/useRouter';
import ApiEngine from 'common/api-engine';
import { Icon } from "@chakra-ui/react";
import {
  MdCheck,
  MdCancel
} from "react-icons/md";
import { Button, Space, Flex } from 'antd';
import { ToggleContent } from '../../../app.js';

/// <summary>
/// Author: YJ
/// </summary>
const TransactionCallback = () => {
    var _location = useLocation();
    var _router = useRouter();
    const [txnState, setTxnState] = useState();
	const { toggleTopBar } = useContext(ToggleContent);

    const { orderId, status, amount, currency } = useParams();

    useEffect(() => {
        if (toggleTopBar != null) {
            toggleTopBar(false);
        }

        return() => {
            toggleTopBar(true);
        }
    }, [toggleTopBar])

    useEffect(() => {
        (async() => {
            if (
                stringIsNullOrEmpty(orderId)
                ||
                stringIsNullOrEmpty(status)
                ||
                stringIsNullOrEmpty(amount)
                ||
                stringIsNullOrEmpty(currency)
            ) {
                throw 'Invalid session';
            }

            try {
                const response = await ApiEngine.post('/transaction/callback', createMultiPartFormBody({
                    orderId: orderId,
                    status: status,
                    amount: amount,
                    currency: currency
                }), {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                });

                if (!response[ApiKey._API_SUCCESS_KEY]) {
                    throw response[ApiKey._API_MESSAGE_KEY];
                }
                
                if (!isObjectEmpty(response[ApiKey._API_DATA_KEY])) {
                    if (response[ApiKey._API_DATA_KEY]["status"] == OrderStatus._SUCCESS) {
                        setTxnState({ status: true, title: 'Success', message: "Payment success" });
                    }
                    else if (response[ApiKey._API_DATA_KEY]["status"] == OrderStatus._FAILED) {
                        setTxnState({ status: false, title: 'Error', message: "Payment failed" });
                    }
                }
                else {
                    setTxnState({ status: false, title: 'Error', message: "Payment error" });
                }
            }
            catch(err) {
                setTxnState({ status: false, title: 'Error', message: "Error" });
            }
        })();
    }, []);

    return (
        <>
            <div style={{ padding: '0 20px', height: '100%', margin: '0 auto', textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Flex gap="middle" align='center' justify='center' wrap vertical>
                {
                    !isObjectEmpty(txnState) && <>
                        {
                            txnState.status
                            ?
                                <Icon width={'150px'} height={'150px'} color={'green'} as={MdCheck} />
                            :
                                <Icon width={'150px'} height={'150px'} color={'red'} as={MdCancel} />
                        }
                        <h1>{txnState.message}</h1>
                        <Space direction='horizontal'>
                            <Button type="primary" success onClick={() => { _router.navigate(WebUrl._DEFAULT, { state: txnState, replace: true }) }}>Done</Button>
                        </Space>
                    </>
                }
            </Flex>
            </div>
        </>
    );
};

export default TransactionCallback;
import { stringIsNullOrEmpty, isObjectEmpty } from "../../common/util";

export const _APP_BUSY = "appBusy";
export const _APP_FREE = "appFree";
export const _SHOW_CUSTOM_DIALOG = 'SHOW_CUSTOM_DIALOG';
export const _DISPOSE_CUSTOM_DIALOG = 'DISPOSE_CUSTOM_DIALOG';
export const _SHOW_CUSTOM_INPUT_DIALOG = 'SHOW_CUSTOM_INPUT_DIALOG';
export const _DISPOSE_CUSTOM_INPUT_DIALOG = 'DISPOSE_CUSTOM_INPUT_DIALOG';

/// <summary>
/// Author: -
/// </summary>
export const setBusy = () => {
    return async dispatch => {
        dispatch({
            type: _APP_BUSY
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _APP_FREE
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
export const showCustomDialog = ({
    success = true,
    title,
    content,
    onConfirm,
    onCancel,
    confirmTxt,
    cancelTxt,
    isManualHideAlert,
    disableHardwareBackPress = false,
}) => {
    return async (dispatch, getState) => {
        dispatch({
            type: _SHOW_CUSTOM_DIALOG,
            data: {
                success: success,
                title: title,
                content: content,
                onConfirm: onConfirm,
                onCancel: onCancel,
                confirmTxt: confirmTxt,
                cancelTxt: cancelTxt,
                isManualHideAlert: isManualHideAlert,
                disableHardwareBackPress: disableHardwareBackPress
            }
        });
    }
};

/// <summary>
/// Author: -
/// </summary>
export const disposeCustomDialog = () => {
    return async dispatch => {
        dispatch({ type: _DISPOSE_CUSTOM_DIALOG });
    }
};

/// <summary>
/// Author: -
/// </summary>
export const showCustomInputDialog = ({
    rowId,
    title,
    content,
    onConfirm,
    onCancel,
    confirmTxt = 'OK',
    cancelTxt,
    isManualHideAlert = false,
    inputType = 'text', // tel | number, ...
    isPassword = false,
    icon,
    isTextArea = false,
    style,
    isRequired = false,
    options = [],
    defaultValue = ""
}) => {
    return async (dispatch, getState) => {
        dispatch({
            type: _SHOW_CUSTOM_INPUT_DIALOG,
            data: {
                rowId: rowId,
                title: title,
                content: content,
                onConfirm: onConfirm,
                onCancel: onCancel,
                confirmTxt: confirmTxt,
                cancelTxt: cancelTxt,
                inputType: inputType,
                isPassword: isPassword,
                icon: icon,
                isTextArea: isTextArea,
                style: style,
                isRequired: isRequired,
                isManualHideAlert: isManualHideAlert,
                options: options,
                defaultValue: defaultValue
            }
        });
    }
};

/// <summary>
/// Author: -
/// </summary>
export const disposeCustomInputDialog = () => {
    return async dispatch => {
        dispatch({ type: _DISPOSE_CUSTOM_INPUT_DIALOG });
    }
};
import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      100: "#fcfaf8",
      200: "#4CC9F0",
      300: "#4895EF",
      400: "#4361EE",
      500: "#3F37C9",
      600: "#3a0ca3",
      700: "#480CA8",
      800: "#560BAD",
      900: "#7209B7",
    },
    brandScheme: {
      100: "#fcfaf8",
      200: "#4CC9F0",
      300: "#4895EF",
      400: "#4361EE",
      500: "#3F37C9",
      600: "#3a0ca3",
      700: "#480CA8",
      800: "#560BAD",
      900: "#7209B7",
    },
    brandTabs: {
      100: "#E9E3FF",
      200: "#3a0ca3",
      300: "#3a0ca3",
      400: "#3a0ca3",
      500: "#3a0ca3",
      600: "#3a0ca3",
      700: "#02044A",
      800: "#190793",
      900: "#02044A",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      300: "#FC8181",
      400: "#F56565",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#00c897",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1B254B",
      800: "#111c44",
      900: "#0b1437",
    },
    gray: {
      100: "#FAFCFE",
      300: "#f4f4f4"
    }
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("white", "navy.900")(props),
        fontFamily: "Lato",
        letterSpacing: "-0.5px",
        color: mode("black", "white")(props)
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "Lato",
        color: mode("black", "white")(props),
      },
      td: {
        color: mode("black", "white")(props),
      },
      th: {
        color: mode("black", "white")(props),
      }
    }),
  },
};

import React from "react";
import {
    Box,
    Heading,
    Text,
    List,
    ListItem,
    ListIcon,
    Divider,
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton
} from "@chakra-ui/react";
import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';

export default function RefundPolicy(props) {
    const _REFUND_MODAL_TYPE = 'REFUND';

    return (
        <Modal size={'4xl'} scrollBehavior="inside" isOpen={props?.modalType == _REFUND_MODAL_TYPE} onClose={() => props?.closeModal()}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <Box padding={8} maxWidth="800px" mx="auto">
                        <Heading as="h1" size="xl" mb="6" >
                            Refund Policy
                        </Heading>
                        <Divider my="6" />
                        <Heading as="h2" size="lg" mb="4" >
                            All Sales Are Final
                        </Heading>
                        <Text mb="4">
                            Thank you for choosing LogoHouse by OMA SENSE INTERNATIONAL (M) SDN. BHD. Due to the nature of our services, all sales are final. Once a transaction is completed, we do not offer refunds, returns, or exchanges under any circumstances.
                        </Text>
                        <Text mb="4">
                            Please review your order carefully before confirming your purchase. By completing your purchase, you acknowledge and agree to our no-refund policy.
                        </Text>
                        <Text mb="4">
                        </Text>
                        <Heading as="h2" size="lg" mb="4">
                            Notes
                        </Heading>
                        <Text mb="2">
                            If you have any questions or concerns prior to making a purchase, feel free to contact our support team. We're here to help you make an informed decision!
                        </Text>
                        <Text mb="4">
                            LogoHouse reserves the right to modify or update this Refund and Return Policy at any time without prior notice.
                        </Text>
                        <Text>
                            We appreciate your understanding of our Refund Policy. If you have any questions or concerns, please do not hesitate to contact our customer support team. We are here to ensure your LogoHouse experience is enjoyable and hassle-free!
                        </Text>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={() => props?.closeModal()}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
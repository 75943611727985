import React, { useEffect, useState } from "react";
import { useRouter } from "../../common/useRouter";
import {
    Button,
    Flex,
    Heading,
    Text,
    Image,
    Container,
    Input,
    Checkbox,
    SimpleGrid,
    GridItem
} from "@chakra-ui/react";
import { ApiKey, _HOME_DEFAULT_IMGAGES } from "../../common/constant";
import { WebUrl } from "../../routes";
import { setBusy, setIdle, showCustomDialog } from "../../application/action/app_action";
import ApiEngine from "../../common/api-engine";
import { stringIsNullOrEmpty } from "common/util";
import useDatabase, { Table } from "../../hooks/useDatabase";
import { convertBlobArrayDataToBlobUrl, convertBlobDataToFile, convertFileToBlobArrayData, convertHexStringToBlobData } from "../../utils/file";
import { Theme } from "../../constants";
import { ViewIcon, RepeatIcon } from '@chakra-ui/icons'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { formatDecimal } from "common/util";
import { v4 as uuidv4 } from 'uuid';
import { connect, useDispatch, useSelector } from "react-redux";
import { HiRocketLaunch, HiOutlineCheckBadge } from "react-icons/hi2";
import { PiInfinityBold } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdOutlineDraw } from "react-icons/md";
import moment from "moment";

export const _SEARCH_DESCRIPTION_CACHE = 'temp.search';
export const _SESSION_KEY = 'temp.session';

/**
 * @author Nelson
 * @author CK - landing page
 */
function Home() {
    var _router = useRouter();
    const _dispatch = useDispatch();
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);

    const _RESYNC_IN_MINS = 20;

    const [images, setImages] = useState([]);
    const [previewImgIndex, setPreviewImgIndex] = useState(null);
    const [selecteds, setSelecteds] = useState([]);

    const [desc, setDesc] = useState(!stringIsNullOrEmpty(sessionStorage.getItem(_SEARCH_DESCRIPTION_CACHE)) ? sessionStorage.getItem(_SEARCH_DESCRIPTION_CACHE) : '');
    const { gets, addOrUpdate, ready, remove, truncate } = useDatabase();

    /**
     * @author Nelson
     */
    useEffect(() => {
        (
            async () => {
                _dispatch(setBusy());

                try {
                    if (ready) {
                        const rawResyncTime = sessionStorage.getItem(_SESSION_KEY);
                        const currentTime = moment.utc().unix();
                        const cacheResponse = await gets(Table.CartItemGeneration);

                        if (cacheResponse.status) {
                            let images = [];

                            if (stringIsNullOrEmpty(rawResyncTime) || parseInt(rawResyncTime) < currentTime) {
                                await truncate(Table.CartItemGeneration);

                                sessionStorage.setItem(_SESSION_KEY, moment.utc().add(_RESYNC_IN_MINS, 'minutes').unix().toString());
                            }
                            else {
                                const items = cacheResponse.data;

                                for (let i = 0; i < items.length; i++) {
                                    images.push({
                                        data: convertBlobArrayDataToBlobUrl(items[i].data),
                                        price: items[i].price,
                                        name: items[i].name,
                                        id: items[i].id
                                    });
                                }
                            }

                            setImages(images);
                        }
                        else {
                            _dispatch(showCustomDialog({ success: false, content: 'Something went wrong. Please try again.', onConfirm: () => window.location.reload() }));
                        }
                    }
                }
                catch (error) {
                    _dispatch(showCustomDialog({ success: false, content: error }));
                }
                finally {
                    _dispatch(setIdle());
                }
            }
        )();

        return () => {
            setImages([]);
            setPreviewImgIndex(null);
            setSelecteds([]);
        }
    }, [ready]);

    /**
     * @author Nelson
     */
    async function onSubmit() {
        if (!_IS_LOGGED_IN) {
            _dispatch(showCustomDialog({
                success: false, content: "Please login to proceed.", onConfirm: () => {
                    _router.navigate(WebUrl._LOGIN);
                }
            }));
        }
        else if (!stringIsNullOrEmpty(desc)) {
            try {
                const response = await ApiEngine.post('/order/generate/bulk', JSON.stringify({
                    description: desc
                }));

                if (!response[ApiKey._API_SUCCESS_KEY]) {
                    throw response[ApiKey._API_MESSAGE_KEY];
                }

                setSelecteds([]);

                await truncate(Table.CartItemGeneration);

                const cartData = response.data;
                let images = [];

                for (let index = 0; index < cartData.items.length; index++) {
                    const cartItem = cartData.items[index];
                    const blob = convertHexStringToBlobData(cartItem.data);
                    const url = URL.createObjectURL(blob);

                    images.push({
                        data: url,
                        price: cartItem.price,
                        name: cartItem.name,
                        id: cartItem.name
                    });

                    await addOrUpdate(Table.CartItemGeneration, {
                        data: await convertFileToBlobArrayData(convertBlobDataToFile(blob, cartItem.name)),
                        price: cartItem.price,
                        id: cartItem.name,
                        name: cartItem.name
                    });
                }

                sessionStorage.setItem(_SEARCH_DESCRIPTION_CACHE, desc);
                setImages(images);
                sessionStorage.setItem(_SESSION_KEY, moment.utc().add(_RESYNC_IN_MINS, 'minutes').unix().toString());
            }
            catch (error) {
                _dispatch(showCustomDialog({ success: false, content: error }));
            }
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: "Kindly provide details about the desired output images you'd like to create." }));
        }
    }

    /**
     * @author Nelson
     */
    async function addToCart() {
        const toAdds = images.filter(e => selecteds.includes(e.name)).slice(0);

        if (toAdds.length === 0) {
            _dispatch(showCustomDialog({ success: false, content: 'Kindly select at least one image before adding to cart.' }));
        }
        else {
            let sources = {};

            toAdds.map((data) => {
                sources[data.name] = data.price
            });

            const response = await ApiEngine.post(`/order/cart`, JSON.stringify({ sources }));

            if (response[ApiKey._API_SUCCESS_KEY]) {
                const cacheResponse = await gets(Table.CartItemGeneration);
                const items = cacheResponse.data.filter(e => selecteds.includes(e.id)).slice(0);

                for (let i = 0; i < items.length; i++) {
                    await remove(Table.CartItemGeneration, items[i].id);
                }

                setImages(images.filter(e => !selecteds.includes(e.name)).slice(0));
                setSelecteds([]);
            }
            else {
                _dispatch(showCustomDialog({ success: false, content: response[ApiKey._API_MESSAGE_KEY] }));
            }
        }
    }

    return (
        <Flex className="landing-page" flexDir={'column'}>
            <Lightbox
                index={previewImgIndex}
                plugins={[Zoom]}
                zoom={{ scrollToZoom: true, maxZoomPixelRatio: 3 }}
                inline={{ style: { aspectRatio: "1 / 1", width: '100%' } }}
                open={!stringIsNullOrEmpty(previewImgIndex)}
                close={() => setPreviewImgIndex(null)}
                slides={[
                    ...images.map((image) => {
                        return {
                            src: image.data,
                            width: 1024,
                            height: 1024,
                            srcSet: []
                        }
                    })
                ]} />
            <Flex zIndex={7} position={'relative'} className='banner-container' height={'100%'} justifyContent={'center'} alignItems={'center'} overflow={'auto'}>
                <Container position={'relative'} zIndex={8}>
                    <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Text className="banner-small-description">For Everyone</Text>
                        <Heading className="banner-big-title">Instant Creativity, Unlimited Possibilities</Heading>
                        <Text className="banner-description">Discover the future of logo design with AI-powered creativity at your fingertips. At LogoHouse, generate a unique, professional logo in just seconds!</Text>
                        <Flex flexDir="row" alignItems={'center'} justifyContent={'center'}>
                            <Input defaultValue={desc} onInput={(e) => setDesc(e.target.value)} placeholder='Drop your ideas here' size='lg' />
                            <Button onClick={onSubmit} className={"btn-grad"}>Generate Now</Button>
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
            {
                _IS_LOGGED_IN && images.length > 0 &&
                <>
                    <Flex width={'100%'} flexDir={'column'} backgroundColor={'rgba(255, 255, 255)'} borderRadius={15} padding={5} marginTop={35} marginBottom={'35px'} alignItems={'center'}>
                        <Text paddingBottom={5} color={Theme.Primary} textAlign={'center'} fontWeight={'bolder'} fontSize={'30px'}>AI-Driven Logo</Text>
                        <Flex flexDir={'row'} flexWrap={'wrap'} justifyContent={'center'}>
                            {
                                images.map((image, index) => {
                                    return (
                                        <Checkbox
                                            isChecked={selecteds.some(id => id === image.name)}
                                            onChange={() => {
                                                let newSelecteds = selecteds.slice(0);

                                                if (newSelecteds.some(id => id === image.name)) {
                                                    newSelecteds = newSelecteds.filter(id => id !== image.name).slice(0);
                                                }
                                                else {
                                                    newSelecteds.push(image.name);
                                                }

                                                setSelecteds([...newSelecteds]);
                                            }}
                                            className="cart-item-checkbox"
                                            key={index}>
                                            <Image width={250} height={250} src={image.data} margin={1.5} />
                                            <Flex flexDir={'row'} justifyContent={'center'} alignItems={'center'}>
                                                <ViewIcon onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    setPreviewImgIndex(index);
                                                }} color={Theme.Primary} marginRight={3} />
                                                <Text color={Theme.Primary} textAlign={'center'}>{`RM${formatDecimal(image.price)}`}</Text>
                                            </Flex>
                                        </Checkbox>
                                    )
                                })
                            }
                        </Flex>
                        <Flex justifyContent={'center'} alignItems={'center'} marginTop={10} marginBottom={4} width={'100%'}>
                            <Button onClick={addToCart} variant={'brand'} maxWidth={180} width={'100%'}>Add To Cart</Button>
                            <Flex cursor={'pointer'} onClick={onSubmit} marginLeft={5} backgroundColor={'var(--chakra-colors-brand-600)'} maxWidth={10} padding={3} borderRadius={10} width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                <RepeatIcon color={'white'} />
                            </Flex>
                        </Flex>
                    </Flex>
                    <hr className="center-diamond" />
                </>
            }
            {
                (!_IS_LOGGED_IN || images.length == 0) &&
                <>
                    <Flex width={'100%'} flexDir={'column'} backgroundColor={'rgba(255, 255, 255)'} borderRadius={15} padding={5} marginTop={35} marginBottom={'35px'} alignItems={'center'}>
                        <Text paddingBottom={5} color={Theme.Primary} textAlign={'center'} fontWeight={'bolder'} fontSize={'30px'}>AI-Driven Logo</Text>
                        <Flex flexDir={'row'} flexWrap={'wrap'} justifyContent={'center'}>
                            {
                                _HOME_DEFAULT_IMGAGES.map((image, index) => {
                                    return (
                                        <Image width={250} height={250} src={image.src} margin={1.5} />
                                    )
                                })
                            }
                        </Flex>
                        <Flex justifyContent={'center'} alignItems={'center'} marginTop={10} marginBottom={4} width={'100%'}>
                            <Button onClick={onSubmit} className={"btn-grad"}>More</Button>
                        </Flex>
                    </Flex>
                    <hr className="center-diamond" />
                </>
            }
            <Flex position={'relative'} className='about-us-container'>
                <Container position={'relative'} zIndex={8}>
                    <Flex flexDir={'row'} alignItems={'center'}>
                        <Flex flex={0.3} justifyContent={'flex-end'}>
                            <Text className="landing-page-title about-us">About Us</Text>
                        </Flex>
                        <Flex flex={0.7}>
                            <Text className="landing-page-description">Welcome to LogoHouse by OMA SENSE INTERNATIONAL (M) SDN. BHD. - where innovation meets design. At OMA SENSE INTERNATIONAL (M) SDN. BHD., our mission is to empower businesses, creators, and individuals to bring their brand visions to life effortlessly. Using advanced AI technology, we deliver professional, high-quality logos tailored to your unique style and industry. Say goodbye to long design timelines and hello to instant creativity - all from the comfort of your screen.</Text>
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
            <hr className="center-diamond" />
            <Flex position={'relative'} className='benefit-container'>
                <Container position={'relative'} zIndex={8}>
                    <Text className="landing-page-title" marginBottom={'10px'}>Why Choose LogoHouse?</Text>
                    <Text className="landing-page-description" textAlign={'center'} fontWeight={'bolder'} marginBottom={'35px'}>Empowering Brands with Instant Creativity</Text>
                    <SimpleGrid columns={{ base: 5, md: 5, sm: 1 }} gap="40px">
                        <GridItem justifyItems={'center'}>
                            <HiRocketLaunch color="#3e37c9" fontSize={'35px'} mb={'8px'} />
                            <Text className="benefit-title">AI-Driven Speed</Text>
                            <Text textAlign={'center'} className="landing-page-description">Get a stunning logo in seconds, powered by cutting-edge AI.</Text>
                        </GridItem>
                        <GridItem justifyItems={'center'}>
                            <PiInfinityBold color="#3e37c9" fontSize={'35px'} mb={'8px'} />
                            <Text className="benefit-title">Infinite Ideas</Text>
                            <Text textAlign={'center'} className="landing-page-description">With endless creative possibilities, your brand always stands out.</Text>
                        </GridItem>
                        <GridItem justifyItems={'center'}>
                            <MdOutlineDraw color="#3e37c9" fontSize={'35px'} mb={'8px'} />
                            <Text className="benefit-title">Tailored to You</Text>
                            <Text textAlign={'center'} className="landing-page-description">Choose colors, styles, and vibes that match your vision.</Text>
                        </GridItem>
                        <GridItem justifyItems={'center'}>
                            <RiMoneyDollarCircleLine color="#3e37c9" fontSize={'35px'} mb={'8px'} />
                            <Text className="benefit-title">Affordable & Accessible</Text>
                            <Text textAlign={'center'} className="landing-page-description">High-quality logos without the high costs.</Text>
                        </GridItem>
                        <GridItem justifyItems={'center'}>
                            <HiOutlineCheckBadge color="#3e37c9" fontSize={'35px'} mb={'8px'} />
                            <Text className="benefit-title">Always Original</Text>
                            <Text textAlign={'center'} className="landing-page-description">Our AI ensures your logo is unique and memorable.</Text>
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Flex>
            {
                !_IS_LOGGED_IN &&
                <Flex position={'relative'} className='join-us-container'>
                    <Container position={'relative'} zIndex={8}>
                        <Flex flexDir={'row'} alignItems={'center'}>
                            <Flex flexDir={'column'} flex={0.7} justifyContent={'flex-end'}>
                                <Text className="join-us-title">Create with Us Now!</Text>
                                <Text className="landing-page-description">Unleash Your Brand, Step Into Innovation!</Text>
                            </Flex>
                            <Flex flex={0.3}>
                                <Button onClick={() => { _router.navigate(WebUrl._REGISTER); }}>Join Now</Button>
                            </Flex>
                        </Flex>
                    </Container>
                </Flex>
            }
        </Flex>
    );
}

export default Home;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Button, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import AdminNavbarLinks from '../../components/navbar/NavbarLinksAdmin';
import { useRouter } from "../../common/useRouter";
import { WebUrl } from "../../routes";
import { connect, useDispatch, useSelector } from "react-redux";
import { FaShoppingCart } from "react-icons/fa";
import { useLocation } from 'react-router-dom';

/// <summary>
/// Author: -
/// </summary>
export default function NavbarHeader(props) {
	const { t } = useTranslation();
	const [scrolled, setScrolled] = useState(false);
	var _router = useRouter();
	const _LOCATION = useLocation();
	const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
	const _USER_DATA = useSelector((state) => state.authState.userData);

	const { secondary, message, brandText } = props;
	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let secondaryText = useColorModeValue('gray.700', 'white');
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(20px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	/// <summary>
	/// Author: -
	/// </summary>
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	/// <summary>
	/// Author: CK
	/// </summary>
	window.addEventListener("scroll", function () {
        const header = document.getElementById("header");
        if (window.scrollY > 50) { // Change background after scrolling 50px
            header.classList.add("scrolled");
        } else {
            header.classList.remove("scrolled");
        }
    });

	console.log(_LOCATION);

	return (
		<Flex zIndex={99} id="header" className='header-container'>
			<Container>
				<Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
					<Link onClick={() => { _router.navigate(WebUrl._DEFAULT); }}><Text className="logo-text">LOGOHOUSE</Text></Link>
					{
						_IS_LOGGED_IN ?
							<Box ms='auto' w={{ sm: '100%', md: 'unset' }}>
								<Flex flexDir={'row'} alignItems={'center'}>
									<Link mr="25px" onClick={() => { _router.navigate(WebUrl._CART); }}><FaShoppingCart color={'#3F37C9'} fontSize={'22px'}/></Link>
									<AdminNavbarLinks
										onOpen={props.onOpen}
										logoText={props.logoText}
										secondary={props.secondary}
										fixed={props.fixed}
										scrolled={scrolled}
									/>
								</Flex>

							</Box> :
							!_LOCATION.pathname.includes("login") ?
							<Button className="sign-btn btn-grad" onClick={() => { _router.navigate(WebUrl._LOGIN); }}>Login</Button> : <></>
					}
				</Flex>
			</Container>
		</Flex>
	);
}

NavbarHeader.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};

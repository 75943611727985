import { useNavigate } from "react-router-dom";
import { isObjectEmpty, isPathExist, filterMenu } from "../common/util";
import { useSelector } from "react-redux";
import { WebUrl, _AUTH_ROUTES, _NON_AUTH_ROUTES } from "../routes";

/// <summary>
/// Author: -
/// </summary>
export function useRouter() {
    const _navigate = useNavigate();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
    const _ROUTES = _IS_LOGGED_IN ? _AUTH_ROUTES : _NON_AUTH_ROUTES;

    /// <summary>
    /// Author: -
    /// </summary>
    function navigate(url = '', state = {}, replace = false) {
        if (isObjectEmpty(isPathExist(filterMenu(_ROUTES, _USER_DATA?.roleId), url))) {
            _navigate(WebUrl._DEFAULT, { replace });
        }
        else {
            _navigate(url, { replace, state });
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function back() {
        const previousLocation = WebUrl._DEFAULT;
        const currentHostname = window.location.hostname;
        const previousHostname = new URL(previousLocation, window.location.href).hostname;

        if (previousHostname !== currentHostname) {
            _navigate(WebUrl._DEFAULT);
        }
        else {
            _navigate(-1);
        }
    }

    return {
        navigate, back
    }
}
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { FormLabel, Text, Stack, useColorModeValue } from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { getValidationMessage, stringIsNullOrEmpty } from "../common/util";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

/// <summary>
/// Author: -
/// </summary>
const CustomPhoneInput = React.forwardRef((props, ref) => {
    const {
        id,
        value,
        onChange,
        errors,
        patternErrorTxt,
        label,
        placeHolder,
        style,
        isRequired = false,
        isDisabled = false,
        ...restProps
    } = props;
    const { t } = useTranslation();

    return (
        <Stack mb={'20px'} {...style}>
            <FormLabel
                display={"flex"}
                ms={"4px"}
                fontSize={"md"}
                fontWeight={"500"}
                mb={"3px"}
            >
                {t(label)} {isRequired && <Text color={'red'}>*</Text>}
            </FormLabel>
            <PhoneInput
                disabled={isDisabled}
                id={id}
                country={'my'}
                value={value}
                onChange={onChange}
                placeholder={t(placeHolder)}
                countryCodeEditable={false}
                enableSearch
                inputProps={{
                    name: id
                }}
                inputClass="phone-input"
                inputStyle={{
                    borderColor: useColorModeValue("#E0E5F2", "white"),
                    color: useColorModeValue("#1B2559", "white"),
                    background: useColorModeValue("transparent", "#111c44"),
                    fontSize: '',
                }}
                searchNotFound={t('NO_RECORD')}
                searchPlaceholder={t('SEARCH')}
            />
            {
                errors && errors[id] &&
                <Text color={'red'} textAlign={'left'}>{t(getValidationMessage(errors[id], patternErrorTxt))}</Text>
            }
        </Stack>
    )
});

export default CustomPhoneInput;
import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import {
    AlertDialog,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button,
    Stack,
    Text,
    Center
} from '@chakra-ui/react';
import { disposeCustomDialog } from "../application/action/app_action";
import { stringIsNullOrEmpty } from "../common/util";

/// <summary>
/// Author: -
/// </summary>
const CustomDialog = ({
    isVisible,
    success,
    title,
    content,
    onConfirm,
    onCancel,
    confirmTxt = 'OK',
    cancelTxt,
    isManualHideAlert = false,
    disableHardwareBackPress = false
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef();

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (isVisible) {
            onOpen();
        }
        else {
            closeDialogHandler();
        }
    }, [isVisible]);

    /// <summary>
    /// Author: -
    /// </summary>
    async function closeDialogHandler() {
        onClose();
        dispatch(disposeCustomDialog());
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function cancelHandler() {
        if (onCancel) {
            onCancel();
        }

        closeDialogHandler();
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const confirmHandler = () => {
        if (onConfirm) {
            onConfirm();
        }

        if (!isManualHideAlert) {
            closeDialogHandler();
        }
    };

    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={() => {
                closeDialogHandler();
            }}
            isOpen={isOpen}
            isCentered
            closeOnOverlayClick={disableHardwareBackPress}
        >
            <AlertDialogOverlay zIndex={'999999'} />
            <AlertDialogContent borderRadius={10} border={'1px solid white'} paddingBlock={5} containerProps={{ zIndex: '999999' }}>
                {
                    !stringIsNullOrEmpty(title) &&
                    <AlertDialogHeader  paddingTop={0} paddingBottom={0} fontSize={'lg'} fontWeight={'bolder'} textAlign={'flex-start'}>
                        {t(title)}
                    </AlertDialogHeader>
                }
                <AlertDialogBody textAlign={'flex-start'} mt={1} minH={'68px'}>
                    <Text fontSize={'md'} fontWeight={'bold'}>
                        {
                            !stringIsNullOrEmpty(content) ? t(content) :
                                success ? t('OPERATION_SUCCESS') : t('INTERNAL_SERVER_ERROR')
                        }
                    </Text>
                </AlertDialogBody>
                <AlertDialogFooter paddingTop={0} paddingBottom={0}>
                    <Stack direction={'row'} w='100%' spacing={2} justifyContent={'flex-end'}>
                        {
                            onCancel &&
                            <Button
                                size={'sm'}
                                minW={'60px'}
                                variant={'link'}
                                color={'red.400'}
                                borderRadius={5}
                                ref={cancelRef}
                                onClick={() => cancelHandler()}>
                                {cancelTxt
                                    ? t(cancelTxt)
                                    : t('CANCEL')}
                            </Button>
                        }
                        <Button
                            size={'sm'}
                            minW={'60px'}
                            onClick={() => confirmHandler()}
                            variant="brand"
                            borderRadius={5}>
                            {t(confirmTxt)}
                        </Button>

                    </Stack>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default CustomDialog;
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useRouter } from "../../common/useRouter";
import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "../../layouts/non-auth/Default";
import illustration from "assets/img/auth/auth.jpg";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "../../components/custom-input";
import CustomPhoneInput from "../../components/custom-phone-input";
import { performLogin } from "../../application/action/auth_action";
import { RegexPattern, ApiKey, Role } from "../../common/constant";
import { WebUrl } from "../../routes";
import { showCustomDialog } from "../../application/action/app_action";
import ApiEngine from "../../common/api-engine";
import Routes from "../../common/api_routes";

/// <summary>
/// Author: -
/// </summary>
function ForgotPassword() {
    var _router = useRouter();
    const { t } = useTranslation();
    const _dispatch = useDispatch();
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.700", "white");

    /// <summary>
    /// Author: -
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            let params = {
                "email": data.email
            };
            var responseJson = await ApiEngine.post(Routes._FORGOT_PASSWORD, params);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showCustomDialog({
                success: responseJson[ApiKey._API_SUCCESS_KEY],
                content: t("FORGOT_PASSWORD_SEND_TO", { email: data.email }),
                onConfirm: () => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        _router.navigate(WebUrl._DEFAULT, true);
                    }
                }
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                alignSelf="self-end"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        {t("FORGOT_PASSWORD")}
                    </Heading>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mb={{ base: "20px", md: "auto" }}
                >
                    <FormControl>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Controller
                                control={control}
                                name="email"
                                defaultValue={""}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) => onChange(value)}
                                        value={value}
                                        placeHolder={"ENTER_EMAIL"}
                                        errors={errors}
                                        patternErrorTxt={'INVALID_EMAIL'}
                                    />
                                )}
                                rules={{
                                    required: "REQUIRED",
                                    pattern: RegexPattern._EMAIL_VALIDATION
                                }}
                            />
                            <Button
                                fontSize="sm"
                                variant="brand"
                                fontWeight="500"
                                w="100%"
                                h="50"
                                mb="24px"
                                type="submit"
                            >
                                {t('SUBMIT')}
                            </Button>
                        </form>
                    </FormControl>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        maxW="100%"
                        mt="0px"
                    >
                        <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                            {t('ALREADY_HAVE_ACCOUNT_?')}
                            <NavLink to={WebUrl._LOGIN}>
                                <Text
                                    color={textColorBrand}
                                    as="span"
                                    ms="5px"
                                    fontWeight="500"
                                >
                                    {t('LOGIN_HERE')}
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ForgotPassword;

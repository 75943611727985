import { useCallback, useEffect, useState } from 'react';
import { _DAYS_TO_SHIP_LIST } from '../common/constant';
import { isObjectEmpty } from '../common/util';
import _ from 'lodash';
import { CartItem, CartItemGeneration } from '../models/database';

/// <summary>
/// Author: Nelson
/// </summary>
export class Table {
    static CartItemGeneration = 'CartItemGeneration';
    static CartItem = 'CartItem';
}

/// <summary>
/// Author: Nelson
/// </summary>
const useDatabase = () => {
    const [db, setDb] = useState(null);
    const [ready, setReady] = useState(false);
    const [listeners, setListeners] = useState([]);

    const _DATABASE_NAME = "SessionDb";
    const _CHANNEL_NAME = 'store-channel-listener';
    const _broadcastable = typeof BroadcastChannel !== 'undefined';
    const _changeChannel = _broadcastable ? new BroadcastChannel(_CHANNEL_NAME) : null;

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        let request = indexedDB.open(_DATABASE_NAME, 1);

        request.onsuccess = function (event) {
            setDb(event.target.result);
        };

        request.onerror = function (event) {
            console.error('Error opening database:', event.target.error);
        };

        request.onupgradeneeded = function (event) {
            let db = event.target.result;

            let objectStore = db.createObjectStore(Table.CartItemGeneration, { keyPath: 'id' });

            Object.keys(CartItemGeneration).map((key) => {
                objectStore.createIndex(CartItemGeneration[key].Name, CartItemGeneration[key].Name, { unique: CartItemGeneration[key].Unique });
            });
        };

        return () => {
            setDb(null);
            setReady(false);
            setListeners([]);
        }
    }, []);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        initialise();
    }, [db]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        if (listeners.length > 0) {
            if (_broadcastable) {
                _changeChannel.onmessage = (event) => {
                    listeners.forEach(listener => listener(event.data));
                };

                return () => {
                    _changeChannel.close();

                    _changeChannel = null;
                }
            }
            else {
                const onStorage = (event) => {
                    if (event.key === _CHANNEL_NAME) {
                        let data = JSON.parse(event.newValue);

                        listeners.forEach(listener => listener(data));
                    }
                };

                window.addEventListener('storage', onStorage);

                return () => {
                    window.removeEventListener('storage', onStorage);
                };
            }
        }
    }, [listeners]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function addEventListener(listener) {
        setListeners(prevListeners => [...prevListeners, listener]);
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function removeEventListener(listener) {
        setListeners(prevListeners => prevListeners.filter(prevListener => prevListener !== listener));
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function onChange(data) {
        if (_broadcastable) {
            _changeChannel.postMessage(data);
        }
        else {
            let newValue = JSON.stringify(data);

            sessionStorage.setItem(_CHANNEL_NAME, newValue);
            window.dispatchEvent(new StorageEvent('storage', { key: _CHANNEL_NAME, newValue }));
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function initialise() {
        if (typeof db !== 'undefined' && db !== null && db instanceof IDBDatabase) {
            setReady(true);
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const add = useCallback(async (tableName, data) => {
        if (ready) {
            return new Promise((resolve, reject) => {
                let transaction = db.transaction([tableName], 'readwrite');
                let objectStore = transaction.objectStore(tableName);
                let request = objectStore.add(data);

                request.onsuccess = function (event) {
                    resolve({ message: 'success', status: true });
                    onChange({ type: 'add', tableName, data });
                };

                request.onerror = function (event) {
                    reject({ message: event.target.error, status: false });
                };
            });
        }
    }, [ready]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const get = useCallback(async (tableName, id) => {
        if (ready) {
            return new Promise((resolve, reject) => {
                let transaction = db.transaction([tableName], 'readonly');
                let objectStore = transaction.objectStore(tableName);
                let request = objectStore.get(id);

                request.onsuccess = function (event) {
                    let table = event.target.result;

                    resolve({ message: !isObjectEmpty(table) ? 'success' : 'failed', status: !isObjectEmpty(table), data: _.cloneDeep(table) ?? null });
                };

                request.onerror = function (event) {
                    reject({ message: event.target.error, status: false, data: null });
                };
            })
        }
    }, [ready])

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const update = useCallback(async (tableName, id, newData) => {
        if (ready) {
            return new Promise((resolve, reject) => {
                let transaction = db.transaction([tableName], 'readwrite');
                let objectStore = transaction.objectStore(tableName);
                let request = objectStore.delete(id);

                request.onsuccess = function (event) {
                    request = objectStore.put(newData);

                    request.onsuccess = function (event) {
                        resolve({ message: 'success', status: true });
                        onChange({ type: 'update', tableName, data: newData });
                    };

                    request.onerror = function (event) {
                        reject({ message: event.target.error, status: false });
                    };
                };

                request.onerror = function (event) {
                    reject({ message: event.target.error, status: false });
                };
            });
        }
    }, [ready]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const addOrUpdate = useCallback(async (tableName, newData) => {
        if (ready) {
            return new Promise((resolve, reject) => {
                let transaction = db.transaction([tableName], 'readwrite');
                let objectStore = transaction.objectStore(tableName);
                let request = objectStore.put(newData);

                request.onsuccess = function (event) {
                    resolve({ message: 'success', status: true });
                    onChange({ type: 'addOrUpdate', tableName, data: newData });
                };

                request.onerror = function (event) {
                    reject({ message: event.target.error, status: false });
                };
            });
        }
    }, [ready]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const remove = useCallback(async (tableName, id) => {
        if (ready) {
            return new Promise((resolve, reject) => {
                let transaction = db.transaction([tableName], 'readwrite');
                let objectStore = transaction.objectStore(tableName);
                let request = objectStore.delete(id);

                request.onsuccess = function (event) {
                    resolve({ message: 'success', status: true });
                    onChange({ type: 'remove', tableName, data: id });
                };

                request.onerror = function (event) {
                    reject({ message: event.target.error, status: false });
                };
            });
        }
    }, [ready]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const gets = useCallback(async (tableName) => {
        if (ready) {
            return new Promise((resolve, reject) => {
                let transaction = db.transaction([tableName], 'readonly');
                let objectStore = transaction.objectStore(tableName);
                let request = objectStore.getAll();

                request.onsuccess = function (event) {
                    resolve({ message: 'success', status: true, data: _.cloneDeep(event.target.result ?? []) });
                };

                request.onerror = function (event) {
                    reject({ message: event.target.error, status: false, data: [] });
                };
            });
        }
    }, [ready]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    const truncate = useCallback(async (tableName) => {
        if (ready) {
            return new Promise((resolve, reject) => {
                let transaction = db.transaction([tableName], 'readwrite');
                let objectStore = transaction.objectStore(tableName);
                let request = objectStore.clear();

                request.onsuccess = function (event) {
                    resolve({ message: 'success', status: true });
                };

                request.onerror = function (event) {
                    reject({ message: event.target.error, status: false });
                };
            });
        }
    }, [ready])

    return {
        db,
        ready,
        add,
        get,
        gets,
        update,
        remove,
        truncate,
        addOrUpdate,
        addEventListener,
        removeEventListener
    };
};

export default useDatabase;
export class CartItemGeneration {
    static Data = {
        Name: 'data',
        Unique: false
    };
    static Price = {
        Name: 'price',
        Unique: false
    }
    static Name = {
        Name: 'name',
        Unique: false
    }
}
/*eslint-disable*/
import React, { useState } from "react";
import {
  Flex,
  Text,
  useColorMode,
  useColorModeValue,
  Container
} from "@chakra-ui/react";
import TermsAndConditions from "./terms-and-conditions";
import PrivacyPolicy from "./privacy-policy";
import RefundPolicy from "./refund-policy";

export default function Footer() {
  const textColor = useColorModeValue("brand.500", "white");
  const { toggleColorMode } = useColorMode();
  const [modalType, setModalType] = useState('');
  const _TNC_MODAL_TYPE = 'TNC';
  const _PRIVACY_MODAL_TYPE = 'PRIVACY';
  const _REFUND_MODAL_TYPE = 'REFUND';

  return (
    <Container position={'relative'} zIndex={8}>
      <Flex
        zIndex='3'
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        alignItems={{
          base: "center",
          xl: "start",
        }}
        justifyContent='space-between'
        padding={'15px'}
      >
        <Text
          color={textColor}
          textAlign={{
            base: "center",
            xl: "start",
          }}
          mb={{ base: "20px", xl: "0px" }}>
          {" "}
          &copy; {1900 + new Date().getYear()}
          <Text as='span' fontWeight='500' ms='4px' color={textColor}>
            LogoHouse. All Rights Reserved.
          </Text>
        </Text>
        <Flex justifyContent={'space-evenly'}>
          <Text as='u' cursor={'pointer'} fontWeight='500' ms='4px' color={textColor} onClick={() => setModalType(_TNC_MODAL_TYPE)}>
            Terms & Conditions
          </Text>
          <Text as='span' fontWeight='500' ms='4px' color={textColor}>
            |
          </Text>
          <Text as='u' cursor={'pointer'} fontWeight='500' ms='4px' color={textColor} onClick={() => setModalType(_PRIVACY_MODAL_TYPE)}>
            Privacy Policy
          </Text>
          <Text as='span' fontWeight='500' ms='4px' color={textColor}>
            |
          </Text>
          <Text as='u' cursor={'pointer'} fontWeight='500' ms='4px' color={textColor} onClick={() => setModalType(_REFUND_MODAL_TYPE)}>
            Refund Policy
          </Text>
        </Flex>
        <TermsAndConditions modalType={modalType} closeModal={() => setModalType('')} />
        <PrivacyPolicy modalType={modalType} closeModal={() => setModalType('')} />
        <RefundPolicy modalType={modalType} closeModal={() => setModalType('')} />
      </Flex>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRouter } from "../../../common/useRouter";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from '../../../common/util';
import { WebUrl } from '../../../routes';
import { Button, Space, Flex } from 'antd';

/// <summary>
/// Author: YJ
/// </summary>
const PaymentProcess = () => {
    var _location = useLocation();
    var _router = useRouter();

    const { txnId, amount } = useParams();
    
    /// <summary>
    /// Author: YJ
    /// </summary>
    async function onBtnClick(success = true) {
        var status = success ? "00" : "11";
        _router.navigate(WebUrl._TRANSACTION_CALLBACK.replace(":orderId", txnId).replace(":status", status).replace(":amount", amount).replace(":currency", "myr"));
    }

    return (
        <div style={{ padding: '0 20px', height: '100%', margin: '0 auto', textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Flex gap="middle" align='center' justify='center' wrap vertical>
                <h1>Transaction Id: {txnId}</h1>
                <h1>Amount: {numberWithCurrencyFormat(parseFloat(amount))}</h1>
                <Space direction='horizontal'>
                    <Button type="primary" success onClick={() => { onBtnClick(true) }}>Success</Button>
                    <Button type="primary" danger onClick={() => { onBtnClick(false) }}>Fail</Button>
                </Space>
            </Flex>
        </div>
    );
};

export default PaymentProcess;

import React from "react";
import {
    Box,
    Heading,
    Text,
    Stack,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from "@chakra-ui/react";

export default function PrivacyPolicy(props) {
    const _PRIVACY_MODAL_TYPE = 'PRIVACY';

    return (
        <Modal size={'4xl'} scrollBehavior="inside" isOpen={props?.modalType == _PRIVACY_MODAL_TYPE} onClose={() => props?.closeModal()}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <Box padding={8} maxWidth="800px" mx="auto">
                        <Heading as="h1" size="xl" mb={6}>Privacy Policy</Heading>
                        <Text mb={6}>
                            Please read the Privacy Policy created by LogoHouse (the “Company”, “we”, “us”, “our”, or LogoHouse) to protect and maintain the privacy of the users of our Website or App (LogoHouse). This Privacy Policy is an integral part of our Terms and Conditions.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>1. Privacy Policy Overview</Heading>
                        <Text mb={6}>
                            If there is any difference between this Privacy Policy and our Terms and Conditions regarding privacy matters, the provisions of this Privacy Policy shall prevail. You must be 18 years or older to use our services. If you are under 18, you will need permission from your parents or legal guardians.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>2. Data Collected on this Website</Heading>
                        <Stack spacing={4}>
                            <Text>
                                <strong>2.1 IP Address:</strong> When visiting the app, the IP address of the user's computer is registered to LogoHouse’s online system. This enables LogoHouse to trace the used computer in case of misuse or unlawful actions in connection with the visit on or use of the app.
                            </Text>
                            <Text>
                                <strong>2.2 Device Information:</strong> This includes hardware model, operating system and version, device ID, and more, used for enhanced service provision and security purposes.
                            </Text>
                            <Text>
                                <strong>2.3 Data Logs:</strong> Including device IP address, access date and time, application features viewed, and more, to monitor and improve our customer support responses.
                            </Text>
                            <Text>
                                <strong>2.4 Personal Information:</strong> In order to process orders, we require users to provide personal information like name and telephone number. This data is collectively referred to as “Personal Information.”
                            </Text>
                        </Stack>

                        <Heading as="h2" size="lg" mt={8} mb={4}>3. How We Use Your Personal Information</Heading>
                        <Text mb={6}>
                            We use your Personal Information for purposes like investigating concerns, processing orders, verifying qualifications for services, billing, improving services, and preventing prohibited or illegal activities. You may opt-out of some features (see Section 11).
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>4. Sharing of Personal Information</Heading>
                        <Text mb={6}>
                            We share demographic data (location, email, device information) on an anonymous basis with partners and advertisers, or third parties when required for services like order fulfillment and fraud prevention.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>5. Data Controller</Heading>
                        <Text mb={6}>
                            LogoHouse is the data controller for the personal data users provide, such as registration data (name, password, and email). Users control the data they choose to disclose on the Website or through their profile on Social Networks.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>6. Data Processor</Heading>
                        <Text mb={6}>
                            LogoHouse may use external services for technical operation, security, and confidentiality of personal data. These data processors are contractually obligated to adhere to LogoHouse’s instructions regarding data processing.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>7. Security Measures</Heading>
                        <Text mb={6}>
                            We use reasonable measures to protect personal data but advise against transmitting sensitive data through unencrypted means. Regular audits are conducted to prevent vulnerabilities and unauthorized access.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>8. Cookie Policy</Heading>
                        <Stack spacing={4}>
                            <Text><strong>8.1 What is a Cookie?</strong> Cookies are small data files stored on your device to make interactions with our Website more efficient.</Text>
                            <Text><strong>8.2 Types of Cookies:</strong> We use session and persistent cookies for website functionality and traffic analysis.</Text>
                            <Text><strong>8.3 Third-party Cookies:</strong> Google Analytics cookies are used for statistical purposes. You may opt out <Link href="http://tools.google.com/dlpage/gaoptout" isExternal>here</Link>.</Text>
                        </Stack>

                        <Heading as="h2" size="lg" mt={8} mb={4}>9. Lost or Stolen Information</Heading>
                        <Text mb={6}>
                            Email us at care@LogoHouse.com.my if your account details are lost, stolen, or used without permission.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>10. Access, Correction, and Deletion</Heading>
                        <Text mb={6}>
                            Users may request access, correction, or deletion of their data by contacting LogoHouse. Updates to your profile can also be made directly on the Website.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>11. Limiting Use of Information</Heading>
                        <Text mb={6}>
                            To “opt-out” of receiving material of interest, notify us at <Link href="mailto:care@LogoHouse.com.my">care@LogoHouse.com.my</Link>.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>12. Changes in Privacy Policy</Heading>
                        <Text mb={6}>
                            LogoHouse may modify this Privacy Policy, providing advance notice for substantial changes. Continued use of the Website implies acceptance of the updated policy.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>13. Contact Us</Heading>
                        <Text mb={6}>
                            For questions about our data practices, contact us at <Link href="mailto:care@LogoHouse.com.my">care@LogoHouse.com.my</Link>.
                        </Text>

                        <Heading as="h2" size="lg" mt={8} mb={4}>14. Disclosure Required by Law</Heading>
                        <Text mb={6}>
                            We may disclose Personal Information as required by law or to protect our rights and property.
                        </Text>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={() => props?.closeModal()}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
import _ from "lodash";
import { toast } from 'react-toastify';
import { InputValidationKey, Role } from "./constant";
import i18next from 'i18next';

/// <summary>
/// Author: -
/// </summary>
export function getValidationMessage(error, customErrorTxt = "") {
  var validationMsg = "";

  switch (error.type) {
    case InputValidationKey._REQUIRED_KEY:
      validationMsg = "REQUIRED_INFO_MSG";
      break;
    case InputValidationKey._MAX_LENGTH_KEY:
      validationMsg = "MAX_LENGTH_EXCEEDED";
      break;
    case InputValidationKey._MIN_LENGTH_KEY:
      validationMsg = "MIN_LENGTH_NO_MET";
      break;
    case InputValidationKey._MAX_KEY:
      validationMsg = "MAX_ERROR";
      break;
    case InputValidationKey._MIN_KEY:
      validationMsg = "MIN_ERROR";
      break;
    case InputValidationKey._PATTERN:
      validationMsg = customErrorTxt;
      break;
    default:
      validationMsg = error.message
      break;
  }

  return validationMsg;
}

/// <summary>
/// Author: -
/// </summary>
export function stringIsNullOrEmpty(data) {
  return (
    data == null ||
    (typeof data != "boolean" && data == "") ||
    data == undefined ||
    (typeof data == "string" && !data.trim())
  );
}

/// <summary>
/// Author: -
/// </summary>
export function isString(value) {
  return typeof (value) === "string"
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
  let formBody = [];

  for (let property in params) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  formBody = formBody.join("&");
  return formBody;
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
  var formBody = new FormData();

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value) && value.length > 0) {
      value.map((item) => {
        if (item.originFileObj) {
          formBody.append(key, item["originFileObj"]);
        }
        else {
          formBody.append(key, value);
        }
      })
    }
    else {
      formBody.append(key, value);
    }
  }

  return formBody;
}

/// <summary>
/// Author: -
/// </summary>
export function isObjectEmpty(data) {
  return data === null || data === undefined || Object.keys(data).length === 0;
}

/// <summary>
/// Author: -
/// </summary>
export function numberWithCurrencyFormat(value, decimal = 2, showDecimal = true) {
  const CURRENCY_DECIMAL = 2;
  var convertedNumber = parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

  if (decimal > CURRENCY_DECIMAL || !showDecimal) {
    let afterDecimalNumber = convertedNumber.split('.');
    let decimalDiff = decimal - CURRENCY_DECIMAL;
    if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
      let processNumber = afterDecimalNumber[1];
      for (let i = processNumber.length - 1; (i > 0 && decimalDiff > 0); i--) {
        if (processNumber.charAt(i) == '0') {
          processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
        }
        else {
          break;
        }
        decimalDiff--;
      }
      convertedNumber = afterDecimalNumber[0];

      if (showDecimal) {
        convertedNumber += "." + processNumber;
      }
    }
  }

  return convertedNumber
}

/// <summary>
/// Author: -
/// </summary>
export function constructQueryString(url, filterObj) {
  if (!stringIsNullOrEmpty(url) && !isObjectEmpty(filterObj)) {
    url += (url.includes("?") ? "&" : "?") +
      Object.keys(filterObj)
        .filter((key) => !stringIsNullOrEmpty(filterObj[key]))
        .map((key) => key + "=" + encodeURIComponent(filterObj[key]))
        .join("&");
  }

  return url;
}

/// <summary>
/// Author: -
/// </summary>
export function getRandomNumber(max = 10000000) {
  return Math.ceil(Math.random() * max);
}

/// <summary>
/// Author: -
/// </summary>
export function stringFormat(value = "", params = []) {
  var finalValue = value;

  for (var i = 0; i < params.length; i++) {
    finalValue = finalValue.replace(`{${i}}`, params[i]);
  }

  return finalValue;
}

/// <summary>
/// Author: -
/// </summary>
export async function convertBase64ToFile(
  data = { fileName: "", contentType: "", content: "" }
) {
  var response = await fetch(data.content);
  var fileData = await response.blob();

  return new File([fileData], data.fileName, { type: data.contentType });
}

/// <summary>
/// Author: -
/// </summary>
export function constructRouteQuery(url, values) {
  console.log(url + values.map(value => '/' + value))
  return url + values.map(value => '/' + value)
}

/// <summary>
/// Author: -
/// </summary>
export function copyToClipboard(value, message, showToast = true) {
  navigator.clipboard.writeText(value);

  if (showToast) {
    toast(message, {
      type: 'info',
      position: "bottom-center",
      autoClose: 1500,
      closeOnClick: true,
      theme: 'dark'
    });
  }
}

/// <summary>
/// Author: -
/// </summary>
export function removeSpecialChar(removeEmoji) {
  console.log(removeEmoji)
  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/g;
  return removeEmoji.replace(regex, '');
}

/// <summary>
/// Author: -
/// </summary>
export async function share(text, image) {
  let data = {};

  if (image) {
    data['files'] = [dataURLtoFile(image, `${text}.jpg`)];
  }
  else {
    data['text'] = text;
  }

  try {
    if (!(navigator.canShare(data))) {
      throw new Error("Can't share data.", data);
    }
    await navigator.share(data);
  }
  catch (err) {
    console.error(err.name, err.message);
  }
}

/// <summary>
/// Author: -
/// </summary>
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mimeType = arr[0].match(/:(.*?);/)[1],
    decodedData = atob(arr[1]),
    lengthOfDecodedData = decodedData.length,
    u8array = new Uint8Array(lengthOfDecodedData);
  while (lengthOfDecodedData--) {
    u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
  }
  return new File([u8array], filename, { type: mimeType });
};

/// <summary>
/// Author: -
/// </summary>
export const deepCopy = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj; // Return the value if obj is not an object
  }

  if (Array.isArray(obj)) {
    return obj.map(item => deepCopy(item)); // Recursively copy array
  }

  const copiedObj = {};
  for (const key in obj) {
    // Check if property is a direct property of the object (not inherited)
    if (Object.hasOwnProperty.call(obj, key)) {
      copiedObj[key] = deepCopy(obj[key]); // Recursively copy each property
    }
  }

  return copiedObj;
};

/// <summary>
/// Author: -
/// </summary>
export const filterMenu = (routes, userRoleId) => {
  let copied = deepCopy(routes);
  return copied.filter(item => {
    if (!item.roles || item.roles.includes(userRoleId)) {
      if (item.items) {
        let copiedItems = deepCopy(item.items);
        item.items = filterMenu(copiedItems, userRoleId);
      }

      return true;
    }

    return false;
  });
};

/// <summary>
/// Author: -
/// </summary>
export const trimPath = (path) => {
  const parts = path.split('/').filter(Boolean); // Split by '/' and remove empty strings
  return `/${parts[0]}`; // Take only the first part
}

/// <summary>
/// Author: -
/// </summary>
export const isPathExist = (items, targetPath) => {
  targetPath = targetPath.indexOf('?') > -1 ? targetPath.substring(0, targetPath.indexOf('?')) : targetPath;

  for (const item of items) {
    if (trimPath(item.path) === trimPath(targetPath)) {
      return item;
    }
    if (item.items && item.items.length > 0) {
      const pathExistsInSubItems = isPathExist(item.items, targetPath);
      if (pathExistsInSubItems) {
        return pathExistsInSubItems;
      }
    }
  }
  return null;
};

/// <summary>
/// Author: -
/// </summary>
export const stringToIntArray = (str) => {
  return str.split(',').map(num => parseInt(num.trim(), 10));
}

/**
 * @author Nelson
 * @param {*} value 
 * @param {*} precision 
 * @returns 
 */
export function roundUp(value = 0, precision = 2) {
  const FULL_NUMBER_POSITION = 0;
  const DECIMAL_PLACES_POSITION = 1;
  const ROUND_UP_RULE_VALUE = 4;
  const INCREMENT_VALUE = 1;

  if (precision < 0) {
    precision = 0;
  }

  let finalValue = 0;

  if (!stringIsNullOrEmpty(value)) {
    finalValue = parseFloat(value.toString());
    let unit = parseInt('1'.padEnd(precision + 1, '0'));
    let format = value * unit;
    let formats = format.toString().split('.');
    let hasDecimalPlaces = formats.length > 1;

    if (hasDecimalPlaces) {
      let fullNumber = parseInt(formats[FULL_NUMBER_POSITION])
      let decimalValue = parseInt(formats[DECIMAL_PLACES_POSITION][0]);

      if (decimalValue > ROUND_UP_RULE_VALUE) {
        fullNumber = value >= 0 ? fullNumber + INCREMENT_VALUE : fullNumber - INCREMENT_VALUE;
      }

      finalValue = fullNumber / parseFloat(unit);
    }
  }

  return finalValue;
}

/**
 * @author Nelson
 * @param {*} value 
 * @param {*} precision 
 * @param {*} format 
 * @returns 
 */
export function formatDecimal(value = 0, precision = 2, format = true) {
  let finalValue = '';

  if (!stringIsNullOrEmpty(value?.toString()) && !isNaN(value)) {
    finalValue = roundUp(value, precision);

    if (format === true) {
      finalValue = finalValue.toLocaleString('en', { minimumFractionDigits: precision, maximumFractionDigits: precision });
    }
  }

  return finalValue;
}